import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import AuthStorage from '../../../lib/AuthStorage'
import { AuthState, TokenInfo } from './types'

const initialState: AuthState = {
  isAppLoaded: false,
  exp: AuthStorage.tokenInfo.exp,
  notification: AuthStorage.tokenInfo.notification,
  user: AuthStorage.tokenInfo.user,
  apps: AuthStorage.tokenInfo.apps,
  company: AuthStorage.tokenInfo.company
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAppState: (state, { payload: tokenInfo }: PayloadAction<TokenInfo>) => {
      // double checks if the storage is complete
      if (AuthStorage.tokenInfo.isStorageComplete) {
        // Sets global state with the token info
        Object.assign(state, tokenInfo)
        // Sets global isAppLoaded
        state.isAppLoaded = true
        return state
      }

      return state
    },

    setLogout: () => {
      // user cleanup
      // first deletes auth storage
      AuthStorage.reset()
      // then resets the state
      return { ...initialState }
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { setAppState, setLogout } = slice.actions

// available selectors - add more as needed
export const selectUser = (state: RootState) => state.auth.user
export const selectIsAppLoaded = (state: RootState) => state.auth.isAppLoaded

// exports slice reducer
export default slice.reducer
