import { defineStyleConfig } from '@chakra-ui/react'

const Tooltip = defineStyleConfig({
  baseStyle: {
    fontSize: 'md',
    hasArrow: true,
    bg: 'background.surface',
    color: 'text.default'
  }
})

export default Tooltip
