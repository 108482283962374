import { MediaState, PickedMedia, UploadedMedias, UploadedMediasErrors } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

const initialState: MediaState = {
  uploadedMedias: null,
  uploadedMediasErrors: null,
  pickedMedia: null
}

const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setUploadedMedias: (
      state,
      {
        payload: { uploadedMedias, handlerId }
      }: PayloadAction<{ uploadedMedias: UploadedMedias; handlerId: string }>
    ) => {
      state.uploadedMedias = uploadedMedias
      if (uploadedMedias?.length) {
        state.pickedMedia = {
          id: handlerId,
          url: uploadedMedias[0].url
        }
      }

      return state
    },

    setPickedMedia: (state, { payload: pickedMedia }: PayloadAction<PickedMedia>) => {
      state.pickedMedia = pickedMedia
      return state
    },

    setUploadedMediasError: (
      state,
      { payload: uploadedMediasErrors }: PayloadAction<UploadedMediasErrors>
    ) => {
      state.uploadedMediasErrors = uploadedMediasErrors
      return state
    },

    resetMediasState: (state) => {
      state.uploadedMedias = null
      state.uploadedMediasErrors = null
      state.pickedMedia = null
      return state
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { setUploadedMedias, setUploadedMediasError, resetMediasState, setPickedMedia } =
  slice.actions

// available selectors - add more as needed
export const selectUploadedMedias = (state: RootState) => state.media.uploadedMedias
export const selectPickedMedia = (state: RootState) => state.media.pickedMedia
export const selectUploadedMediasErrors = (state: RootState) => state.media.uploadedMediasErrors
// exports slice reducer
export default slice.reducer
