import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { StoreAdminState, StoreAdminMarket, StoreAdminStore } from './types'

const initialState: StoreAdminState = {
  currentMarket: {
    name: '',
    code: '',
    stores: []
  },
  currentStore: {
    id: '',
    name: '',
    channel: {
      domain: '',
      code: '',
      host: ''
    }
  }
}

const slice = createSlice({
  name: 'storeAdmin',
  initialState,
  reducers: {
    setCurrentMarket: (state, { payload: market }: PayloadAction<StoreAdminMarket>) => {
      state.currentMarket = market
      return state
    },
    setCurrentStore: (state, { payload: store }: PayloadAction<StoreAdminStore>) => {
      state.currentStore = store
      return state
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { setCurrentMarket, setCurrentStore } = slice.actions

// available selectors - add more as needed
export const selectStoreAdmin = (state: RootState) => state.storeAdmin

// exports slice reducer
export default slice.reducer
