const AppConfigFactory = (function () {
  function AppConfigSingleton(this: IAppConfigSingleton) {
    this.apiUrl = process.env.NEXT_PUBLIC_API_HOST
    this.isApiDebug = true
    this.isDevelopment = process.env.NODE_ENV !== 'production'
    this.defaultPaginationSize = 10
    this.defaultSelectPaginationSize = 30
    this.currentAppId = 13
    this.excludedAppIds = [0]
    this.appName = 'Storefront Admin'
    this.localStorageKey = 'grandsuite-app'
    this.widgetViewsWithSubViews = ['STATIC_VIEWS']
    this.alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÅ'
  }

  let instance: IAppConfigSingleton

  return {
    getInstance: function () {
      if (!instance) {
        //eslint-disable-next-line
        // @ts-ignore
        instance = new AppConfigSingleton()
      }
      return instance
    }
  }
})()

export default AppConfigFactory.getInstance()

interface IAppConfigSingleton {
  apiUrl: string | undefined
  isApiDebug: boolean
  currentAppId: number
  excludedAppIds: number[]
  defaultPaginationSize: number
  defaultSelectPaginationSize: number
  isDevelopment: boolean
  appName: string
  localStorageKey: string
  widgetViewsWithSubViews: string[]
  alphabet: string
}
