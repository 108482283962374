import appConfig from '../appConfig'

const LOCALSTORAGE_KEY = appConfig.localStorageKey

const createKey = (key: string) => {
  return `${LOCALSTORAGE_KEY}-${key}`
}

const getLS = (key: string) => {
  const item = typeof window !== 'undefined' && window?.localStorage.getItem(createKey(key))

  if (item) {
    return JSON.parse(item)
  }

  return null
}

const setLS = (key: string, value: string) => {
  return localStorage.setItem(createKey(key), value)
}

const removeLS = (key: string) => {
  return localStorage.removeItem(createKey(key))
}

const tokenInfo = {
  get user() {
    return getLS('user')
  },
  get notification() {
    return getLS('notification')
  },
  get exp() {
    return getLS('exp')
  },
  get company() {
    return getLS('company')
  },
  get apps() {
    return getLS('apps')
  },
  get permissions() {
    return getLS('permissions')
  },
  get isStorageComplete() {
    return (
      !!this.user &&
      !!this.notification &&
      !!this.exp &&
      !!this.company &&
      !!this.apps &&
      !!this.permissions
    )
  }
}

interface IAuthStorage {
  tokenInfo: any
}

const AuthStorage = function (this: IAuthStorage) {
  this.tokenInfo = Object.create(tokenInfo)
}

AuthStorage.prototype.setLoginInfo = function (tokenInfo: string) {
  // saves all the properties of token info in the local storage
  for (const key in tokenInfo as any) {
    const value = tokenInfo[key as any]
    setLS(key, JSON.stringify(value))
  }
}

AuthStorage.prototype.reset = function () {
  // removes all the properties of tokenInfo from the local storage
  for (const key in this.tokenInfo) {
    removeLS(key)
  }
}
// @ts-expect-error -- create Singleton
// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthStorage()
