import { extendTheme, ThemeConfig, withDefaultColorScheme } from '@chakra-ui/react'
import { colors } from './colors'
import components from './components'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false
}

export const breakpoints = {
  xs: '30em',
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
  xxl: '100em'
}

export const customTheme = extendTheme(
  {
    config,
    breakpoints,
    roundness: 4,
    border: `1.2px solid rgba(0, 66, 105, 1)`,
    borderRadius: {
      default: 9999
    },
    topBarHeight: 10,
    sideBarWidth: 12,
    transition: {
      default: 'all 0.1s cubic-bezier(.17,.67,.83,.67)',
      medium: 'all 0.75s cubic-bezier(.17,.67,.83,.67)',
      mediumEaseIn: 'all 0.25s ease-in'
    },
    colors,
    components,
    textStyles: {
      'header-alt': {
        color: colors.text.mild,
        fontWeight: 'bold'
      }
    }
  },
  withDefaultColorScheme({ colorScheme: 'colorScheme.primary' })
)
