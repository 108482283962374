import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../store'
import { ModalPayloadProps, ModalState } from './types'

const initialState: ModalState = {
  isHidden: true,
  componentId: 'default',
  title: '',
  size: 'sm',
  extraArgs: null
}

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalChildren: (
      state,
      { payload: { componentId, title, size, extraArgs } }: PayloadAction<ModalPayloadProps<any>>
    ) => {
      // sets the component id in the store which will be used by ModalChildren component to render the correct children
      state.componentId = componentId
      state.title = title
      state.size = size
      state.extraArgs = extraArgs || null
      return state
    },

    setModalOpen: (state) => {
      state.isHidden = false
      return state
    },

    setModalClose: (state) => {
      state.isHidden = true
      return state
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { setModalChildren, setModalOpen, setModalClose } = slice.actions

// available selectors - add more as needed
export const selectModalState = (state: RootState) => state.modal

// exports slice reducer
export default slice.reducer
