export const colors = {
  primary: '#1f9fe7', //brand.blue
  secondary: '#e9e9e9',
  success: {
    // success feedback for alerts / validation / text
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    500: '#38A169',
    600: '#2F855A',
    700: '#276749',
    800: '#22543D',
    900: '#1C4532'
  },
  error: {
    // error feedback for alerts / validation / text
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B'
  },
  warning: {
    // warning feedback for alerts / validation / text
    50: '#FFFFF0',
    100: '#FEFCBF',
    200: '#FAF089',
    300: '#F6E05E',
    400: '#ECC94B',
    500: '#D69E2E',
    600: '#B7791F',
    700: '#975A16',
    800: '#744210',
    900: '#5F370E'
  },
  info: {
    // informative content and alerts
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923'
  },
  colorScheme: {
    primary: {
      100: '#ddf7ff',
      200: '#b3e2fd',
      300: '#88cef5',
      400: '#5bbaef',
      500: '#30a6e9',
      600: '#168dcf', // default
      700: '#086da2', // _active
      800: '#004e75',
      900: '#003049'
    },
    secondary: {
      100: '#f2f2f2',
      200: '#d9d9d9',
      300: '#bfbfbf',
      400: '#a6a6a6',
      500: '#8c8c8c',
      600: '#737373',
      700: '#595959',
      800: '#404040',
      900: '#262626'
    }
  },
  background: {
    default: '#FFF',
    surface: '#F6F6F6',
    dark: 'rgba(0, 66, 105, 1)',
    darkest: 'rgba(0,45,71, 1)',
    translucent: 'rgba(244,244,244, 0.90)',
    overlayHalf: 'rgba(0,0,0, 0.25)',
    overlay: 'rgba(0,45,71, 0.75)'
  },
  text: {
    default: '#1c2633',
    // used as mild with light backgrounds
    mild: 'rgba(28,38,51,0.5)',
    light: 'white',
    // used as mild with dark backgrounds
    darkMild: 'rgba(254,254,254,0.3)',
    colored: '#315d91'
  },
  status: {
    success: 'rgba(29,195,149, 1)',
    warning: '#eed892',
    error: '#f44336'
  },
  table: {
    lightBlue: '#ccd5da'
  },
  brand: {
    pink: '#ff6384ff',
    red: '#F6A1A1',
    plum: '#bb6bd9',
    plumDarker: '#9b42a0',
    plumLighter: '#cf97e4',
    teal: '#26aebd',
    tealDarker: '#007b8b',
    tealLighter: '#67c6d1',
    yellow: '#feca57',
    orange: '#f7b092',
    violet: '#7448ff',
    violetDarker: '#6c42f0',
    violetLighter: '#9d7fff',
    blue: '#1f9fe7',
    blueLighter: '#62bbef',
    blueDarker: '#315d91',
    blueDarkest: 'rgb(47, 49, 79)',
    lightgrey: '#e6e6e6',
    grey: '#d3d3d3',
    greyDarker: '#cccccc',
    // used as badge backgrounds
    plumExtraLight: 'rgba(207,151,228,0.45)',
    tealExtraLight: 'rgba(139,221,234,0.45)',
    blueExtraLight: 'rgba(98,187,239,0.45)'
  }
}
