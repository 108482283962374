import { ComponentStyleConfig } from '@chakra-ui/react'

const ButtonStyle: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    shadow: 'none',
    py: 2,
    px: 3,
    h: 'auto',
    transition: 'all 0.35s cubic-bezier(.08,.52,.52,1)',
    _hover: {
      filter: 'brightness(1.1) contrast(100%)',
      _disabled: {
        bgColor: 'text.mild'
      }
    },
    _active: {
      filter: 'brightness(1.2) contrast(100%)',
      transform: 'scale(0.98)',
      _disabled: {
        transform: 'none'
      }
    },
    _focus: {
      boxShadow: 'none',
      outline: 'none'
    },
    borderRadius: 4
    //borderRadius: customTheme.roundness,
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    granditude: {
      fontSize: 'sm',
      color: 'text.light',
      fontWeight: 'medium',
      //border: customTheme.border,
      _disabled: {
        bgColor: 'text.mild',
        borderColor: 'text.mild',
        opacity: 0.3,
        color: 'text.default',
        cursor: 'default'
      }
    }
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {}
}

export default ButtonStyle
