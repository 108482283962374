import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../store'
import { SlideOutPayloadProps, SlideoutSize, SlideOutState } from './types'

const initialState: SlideOutState = {
  isHidden: true,
  size: 'lg',
  title: '',
  componentId: 'contentManagement',
  footerId: 'default',
  extraArgs: null
}

const slice = createSlice({
  name: 'slideOut',
  initialState,
  reducers: {
    setSlideOutChildren: (
      state,
      {
        payload: { componentId, title, footerId, extraArgs }
      }: PayloadAction<SlideOutPayloadProps<any>>
    ) => {
      state.componentId = componentId
      state.footerId = footerId
      state.title = title
      state.extraArgs = extraArgs || null
      return state
    },

    setSlideOutOpen: (state) => {
      state.isHidden = false
      return state
    },

    setSlideOutClose: (state) => {
      state.isHidden = true
      state.size = initialState.size
      return state
    },
    setSlideOutSize: (state, { payload: { size } }: PayloadAction<{ size: SlideoutSize }>) => {
      state.size = size
      return state
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { setSlideOutChildren, setSlideOutOpen, setSlideOutClose, setSlideOutSize } =
  slice.actions

// available selectors - add more as needed
export const selectSlideOutState = (state: RootState) => state.slideOut

// exports slice reducer
export default slice.reducer
